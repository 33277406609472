import React, { useState, useCallback, useEffect } from "react";
import { Form, Input, Select, Radio, Button, notification } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";

import useCommonData from "hooks/CommonData";
import { validatorPhone } from "helper/commonValidator";
import { validatorPlateNumber } from "helper/licensePlateValidator";
import AddBookingService from '../../services/addBookingService'
import stationsService from '../../services/stationsService'
import { IconCar } from "assets/icons";
import { USER_ROLES } from "constants/permission";
import { geScheduleError } from "constants/errorMessage";
import { optionVehicleType } from "constants/vehicleType";
import BasicButtonPrimary from "components/BasicButtonPrimary";

const FormCreateNewBooking = ({reloadData}) => {
  const { t: translation } = useTranslation()
  const { Option } = Select;
  const { metaData } = useCommonData();

  const [form] = Form.useForm();
  const [notificationMethod, setNotificationMethod] = useState("SMS");
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [date, setDate] = useState(null);
  const [listDate, setListDate] = useState([]);
  const [listTime, setListTime] = useState([]);
  const [vehicleType, setVehicleType] = useState(1);
  const [loading, setLoading] = useState(false);
  const SCHEDULE_ERROR = geScheduleError(translation);
  const setting = useSelector(state => state.setting)
  const user = useSelector(state => state.member)
  const { enableConfigBookingOnToday, enableConfigAllowBookingOverLimit } = setting;
  const isAdmin = USER_ROLES.ADMIN === user.appUserRoleId;
  const setExceed = enableConfigBookingOnToday === 1 || isAdmin ? 1 : 0;
  const nextMonth = moment().add(1, "month").subtract(setExceed, "day").format("DD/MM/YYYY");

  const optionsColor = [
    {
      value: 1,
      label: <div className="accreditation__parent">{translation("accreditation.white")}<IconCar className=" accreditation__circle-white" /></div>
    },
    {
      value: 2,
      label: <div className="accreditation__parent">{translation("accreditation.blue")}<IconCar className=" accreditation__circle-blue" /></div>
    },
    {
      value: 3,
      label: <div className="accreditation__parent">{translation("accreditation.yellow")}<IconCar className=" accreditation__circle-yellow" /></div>
    }
  ];

  useEffect(()=>{
    fetchDataDate();
  },[])

  const fetchDataDate = useCallback(() => {
    stationsService.getDate({
      stationsId: user.stationsId,
      startDate: moment().subtract(setExceed, "day").format("DD/MM/YYYY"),
      endDate: nextMonth,
      vehicleType
    }).then(result => {
      if (result) setListDate(result);
    });
  }, [nextMonth, setExceed, vehicleType]);

  const fetchDataTime = useCallback(() => {
    stationsService.getTime({
      stationsId: user.stationsId,
      date,
      vehicleType
    }).then(result => {
      if (result) setListTime(result);
    });
  }, [date, vehicleType]);

  useEffect(() => {
    if (date) fetchDataTime();
  }, [date, fetchDataTime]);

  const onFinish = (values) => {
    setLoading(true);
    const newDate = { notificationMethod, scheduleType: 1, ...values };
    AddBookingService.AddBooking({
      ...newDate,
      licensePlates: values.licensePlates.toUpperCase()
    }).then(result => {
      if (result && result.issSuccess) {
        form.resetFields();
        notification["success"]({
          message: "",
          description: translation('landing.sendSuccess')
        });
        reloadData();
      } else {
        notification["error"]({
          message: "",
          description: SCHEDULE_ERROR[result.error] || translation('addBookingSuccess.errorExists')
        });
      }
      setLoading(false);
    });
  };

  return (
    <Form
      form={form}
      name="addBooking"
      autoComplete="off"
      onFinish={onFinish}
      size="small"
      colon={false}
      labelAlign="right"
      initialValues={{
        vehicleType: 1,
        licensePlateColor: 1,
        scheduleType: 1,
      }}
      onValuesChange={(values) => {
        if (values.dateSchedule) setDate(values.dateSchedule);
      }}
      labelCol={{ xl: 7, lg: 24, md: 24, span: 7 }}
      wrapperCol={{ xl: 17, lg: 24, md: 24, span: 17 }}
    >
      <FormField translation={translation} label={translation("landing.fullname")} name="fullnameSchedule" placeholder="Nguyễn văn A" />
      <FormField translation={translation} label={translation("landing.phoneNumber")} name="phone" placeholder="0348928492" validator={validatorPhone} notificationMethod={notificationMethod} />
      <FormField translation={translation} label={translation("landing.license-plates")} name="licensePlates" placeholder="30A48393" validator={validatorPlateNumber} />
      
      <Form.Item
        name="licensePlateColor"
        rules={[{ required: true, message: translation("accreditation.isRequire") }]}
        label={translation("accreditation.licensePlateColorLabel")}
      >
        <Radio.Group className="licensePlateColor">
          {optionsColor.map(color => (
            <Radio key={color.value} value={color.value}>{color.label}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="vehicleType"
        rules={[{ required: true, message: translation("accreditation.isRequire") }]}
        label={translation("landing.vehicleType")}
      >
        <Select
          size="middle"
          placeholder={translation("landing.select-vehicleType")}
          options={optionVehicleType(translation)}
        />
      </Form.Item>

      <Form.Item
        name="scheduleType"
        rules={[{ required: true, message: translation("accreditation.isRequire") }]}
        label={translation("listSchedules.scheduleType")}
      >
        <Select className="w-100" size="middle" placeholder={translation("listSchedules.filterByScheduleType")}>
          {Object.values(metaData?.SCHEDULE_TYPE || {}).map(item => (
            <Option key={item.scheduleType} value={item.scheduleType}>{item.scheduleTypeName}</Option>
          ))}
        </Select>
      </Form.Item>

      <DateField translation={translation} listDate={listDate} vehicleType={vehicleType} setDate={setDate} isDateSelected={isDateSelected} />
      <TimeField translation={translation} listTime={listTime} isDateSelected={isDateSelected} date={date} />

      <Form.Item className="d-flex justify-content-center" shouldUpdate>
        {() => {
          const isError = form.getFieldsError().every(item => item.errors.length === 0);
          return (
              // <Button type="primary" size="middle" htmlType={isError ? "submit" : "button"} loading={loading}>
              //   {translation("landing.send")}
              // </Button>
              <BasicButtonPrimary
                size="middle"
                htmlType={isError ? "submit" : "button"}
                loading={loading}
                text={translation("accreditation.save")}
              />
          );
        }}
      </Form.Item>
    </Form>
  );
};

const FormField = ({ label, name, placeholder, validator, notificationMethod, translation }) => (
  <Form.Item
    name={name}
    label={label}
    rules={[{ required: notificationMethod === "SMS", validator: validator && ((_, value) => validator(value,translation)) }]}
    className="col-12 col-md-12 col-lg-12"
  >
    <Input type="text" size="middle" placeholder={placeholder} />
  </Form.Item>
);

const DateField = ({ label,listDate, vehicleType, setDate, isDateSelected, translation }) => (
  <Form.Item
    name="dateSchedule"
    rules={[{ required: true, message: translation("accreditation.isRequire") }]}
    label={translation("landing.date")}
  >
    <Select
      placeholder={translation("landing.select-date")}
      size="middle"
      disabled={!vehicleType}
      onChange={value => value && setDate(value)}
    >
      {listDate.map(item => {
        item.scheduleDateStatus = item.scheduleDateStatus === 99 ? 0 : item.scheduleDateStatus;
        return <Option key={item.scheduleDate} value={item.scheduleDate}>{item.scheduleDate}</Option>;
      })}
    </Select>
  </Form.Item>
);

const TimeField = ({ translation,listTime, isDateSelected,date }) => (
  <Form.Item
    name="time"
    rules={[{ required: true, message: translation("accreditation.isRequire") }]}
    label={translation("landing.time")}
  >
    <Select size="middle" placeholder={translation("landing.select-time")} disabled={!date}>
      {listTime.map(item => <Option key={item.scheduleTime} value={item.scheduleTime}>{item.scheduleTime}</Option>)}
    </Select>
  </Form.Item>
);

export default FormCreateNewBooking;
