import React, { useState } from "react";
import { Typography } from "antd";

const TextExpandable = ({ text, translation, rows=2, style={}, onClick=()=>{} }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div style={{ width: 400 }}>
      <Typography.Paragraph
        onClick={onClick}
        style={style}
        key={expanded} 
        className="sms-content"
        ellipsis={!expanded ? { rows: rows, expandable: true, onExpand: () => setExpanded(true), symbol:translation("sms.show") } : false}
      >
        {text}
      </Typography.Paragraph>

      {expanded && (
        <Typography.Text 
          onClick={() => setExpanded(false)} 
          className="text-primary" 
        >
          {translation("sms.collapse")}
        </Typography.Text>
      )}
    </div>
  );
};

export default TextExpandable;
