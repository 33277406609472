export const PAYMENT_GATEWAY_STATUS = {
  ENABLE: 1,
  DISABLE: 0, 
}

export const ENABLE_STATION_MESSAGE = {
  ENABLE: 1,
  DISABLE: 0,
}

export const STATION_TYPES = {
  MAIN_STATION: 1,
  SUB_STATION: 2,
}