import Request from './request'

export default class AddreditationSettingService {
  static async findById(data={}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationSetting/advanceUser/findById',
        data: data,
      }).then((result = {}) => {
        const { statusCode } = result
        if (statusCode === 200) {
          return resolve({ issSuccess: true  , ...result})
        } else {
          return resolve({ issSuccess: false , ...result })
        }
      })
    })
  }
  static async updateById(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationSetting/advanceUser/updateById',
        data: data,
      }).then((result = {}) => {
        const { statusCode } = result
        if (statusCode === 200) {
          return resolve({ issSuccess: true  , ...result})
        } else {
          return resolve({ issSuccess: false , ...result })
        }
      })
    })
  }
}