import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Table,
  notification,
  Typography,
  Space,
  Spin,
  Select,
  Popconfirm,
  Button,
  Modal,
  Form,
  Input,
} from "antd";
import InspectionProcessService from "./../../services/inspectionProcessService";
import AccreditationService from "./../../services/accreditationService";
import AccreditationTabs from "../../components/AccreditationTabs";
import {
  AccreditationNotificationKey,
  InspectionProcessKey,
  CreateNewCustomerKey,
  ListEditAccreditationKey,
  ListAccreditationKey,
  AccreditationStatisticalKey,
  ListReportStaistic,
} from "constants/accreditationTabs";
import { useHistory } from "react-router-dom";
import { ModalCrime } from "Page/ListCustomers/ModalCrime";
import {
  CloseCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import UnLock from "components/UnLock/UnLock";
import BasicTablePaging from "components/BasicTablePaging/BasicTablePaging";
import { useModalDirectLinkContext } from "components/ModalDirectLink";
import BasicButtonPrimary from "components/BasicButtonPrimary";
import { MIN_COLUMN_WIDTH } from "constants/app";
import FormCreateNewBooking from "./formCreateNewBooking";
import { AccreditationSettingKey } from "constants/accreditationTabs";
import { EXTRA_BIG_COLUMND_WITDTH } from "constants/app";
import StationOperateResult from "services/StationOperateResult";
import { result } from "lodash";
import addKeyLocalStorage from "helper/localStorage";
import { TYPE_NOTIFICATION_SHOW } from "constants/accreditationSetting";
import AddreditationSettingService from "services/AccreditationSettingService";

const SIZE = 5;
const DEFAULT_FILTER = { filter: {}, skip: 0, limit: SIZE };
const RESULT_NAME_LIMIT = 20;

function AccreditationSetting() {
  const [form] = Form.useForm();
  const { t: translation } = useTranslation();
  const [stationCheckingConfig, setStationCheckingConfig] = useState([]);
  const [data, setData] = useState({
    total: 0,
    data: [],
  });
  const [crimePlateNumber, setCrimePlateNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const setting = useSelector((state) => state.setting);
  const [dataFilter, setDataFilter] = useState(DEFAULT_FILTER);
  const user = useSelector((state) => state.member);
  const [isCreate, setIsCreate] = useState(false);
  const { setUrlForModalDirectLink } = useModalDirectLinkContext();
  const [displayOption, setDisplayOption] = useState("");

  function handleDeleteData(id) {
    StationOperateResult.deletedById({
      stationOperateResultId: id,
    }).then((result) => {
      if (result.issSuccess) {
        setData((prevData) => {
          const newData = prevData.data.filter(
            (item) => item.stationOperateResultId !== id
          );
          return {
            total: prevData.total - 1,
            data: newData,
          };
        });
        notification.success({
          message: translation("settingAccreditation.deletedSuccess"),
        });
      } else {
        notification.error({
          message: translation("settingAccreditation.deletedFailed"),
        });
      }
    });
  }

  useEffect(() => {
    StationOperateResult.find().then((result) => {
      setData((prevData) => ({
        ...prevData,
        total: result.length,
        data: result,
      }));
    });
    AddreditationSettingService.findById().then((result) => {
      if (result.issSuccess) {
        const customerDisplayFieldName = result.data?.customerDisplayFieldName;
        setDisplayOption(() => {
          const optionSelected = TYPE_NOTIFICATION_SHOW.find(
            (item) => item.field === customerDisplayFieldName
          );
          return optionSelected?.value;
        });
      }
    });
    isMobileDevice(window.outerWidth);
  }, []);

  const columns = [
    {
      title: (
        <Typography.Paragraph className="accreditation__title">
          {translation("accreditation.listResultChecking")}
        </Typography.Paragraph>
      ),
      dataIndex: "stationOperateResultName",
      key: "stationOperateResultName",
      width: EXTRA_BIG_COLUMND_WITDTH,
      render: (record) => {
        return <div>{record}</div>;
      },
    },
  ];

  const columnsEnd = columns.concat([
    {
      title: (
        <Typography.Paragraph className="accreditation__title d-flex justify-content-center align-items-center">
          <BasicButtonPrimary
            onclick={() => {
              setIsCreate(true);
            }}
            text={translation("inspectionProcess.add")}
            icon={<PlusOutlined className="addIcon" />}
            className="d-flex justify-content-center align-items-center"
          />
        </Typography.Paragraph>
      ),
      dataIndex: "stationOperateResultId",
      key: "stationOperateResultId",
      width: MIN_COLUMN_WIDTH,
      align: "center",
      render: (record) => {
        return (
          <Space size={16}>
            <Popconfirm
              title={translation("accreditation.popConfirmSetting")}
              onConfirm={() => handleDeleteData(record)}
              okText={translation("accreditation.yes")}
              cancelText={translation("accreditation.no")}
            >
              <div className="completed__icon">
                <CloseCircleOutlined style={{ color: "#F5222D" }} />
              </div>
            </Popconfirm>
          </Space>
        );
      },
    },
  ]);

  const isMobileDevice = (value) => {
    if (value < 768) {
      dataFilter.limit = 10;
    }
  };

  const FormField = ({
    label,
    name,
    placeholder,
    validator,
    notificationMethod,
    translation,
  }) => (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: notificationMethod === "SMS",
          validator: validator && ((_, value) => validator(value, translation)),
        },
      ]}
      className="col-12 col-md-12 col-lg-12"
    >
      <Input type="text" size="middle" placeholder={placeholder} />
    </Form.Item>
  );

  const onFinish = () => {
    if (data.total >= RESULT_NAME_LIMIT) {
      return notification.error({
        message: translation("settingAccreditation.resultNameLimit"),
      });
    }
    const settingData =
      JSON.parse(window.localStorage.getItem(addKeyLocalStorage("setting"))) ||
      {};
    const { stationsId } = settingData || { stationsId: null };
    setLoading(false);
    const value = form.getFieldValue("nameResult")
    if (!value || value.trim() === "") {
      return notification.error({
        message: translation("settingAccreditation.resultNameRequired"),
      });
    }
    StationOperateResult.create({
      stationOperateResultName: value || "",
      stationId: stationsId || null,
    }).then((result) => {
      if (result.statusCode === 200) {
        form.setFieldValue("nameResult", "");
        notification.success({
          message: translation("settingAccreditation.createdSuccess"),
        });
        setData((prevData) => {
          return {
            ...prevData,
            total: prevData.total + 1,
            data: [
              ...prevData.data,
              { stationOperateResultName: value },
            ],
          };
        });
      } else {
        notification.error({
          message: translation("settingAccreditation.createdFailed"),
        });
      }
    });
    setIsCreate(false);
  };

  const handleChangeDisplayField = async (value) => {
    const optionSelected = TYPE_NOTIFICATION_SHOW.find(
      (item) => item.value === value
    );
    localStorage.setItem("displayOption", JSON.stringify(optionSelected));
    await AddreditationSettingService.updateById({
      data: {
        customerDisplayFieldName: optionSelected.field,
      },
    }).then((result) => {
      if (result.issSuccess) {
        notification.success({
          message: translation("settingAccreditation.updateSuccess"),
        });
      } else {
        notification.error({
          message: translation("settingAccreditation.updateFailed"),
        });
      }
    });
  };

  return (
    <Fragment>
      <AccreditationTabs
        AccreditationSetting={() => (
          <div>
            {setting.enableOperateMenu === 0 ? (
              <UnLock />
            ) : (
              <div>
                <Space
                  size={24}
                  wrap
                  className="justify-content-md-center mb-3"
                >
                  <div>{translation("settingAccreditation.showNotificationForCustomer")}</div>
                  <Select
                    style={{ minWidth: "250px" }}                    
                    onChange={handleChangeDisplayField}
                    defaultValue={
                      displayOption ||
                      JSON.parse(localStorage.getItem("displayOption")) ||
                      TYPE_NOTIFICATION_SHOW[0].value
                    }
                    placeholder={translation("accreditation.inspectionCycle")}
                  >
                    {TYPE_NOTIFICATION_SHOW.map((item, _) => {
                      return (
                        <Select.Option value={item.value}>
                          {item.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Space>
                {loading ? (
                  <div
                    className="d-flex justify-content-center "
                    style={{ height: "100vh" }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <div className="d-grid">
                    <div className="col-lg-6 col-sm-12 border p-2">
                      <div>
                        <Table
                          className="noselect"
                          dataSource={data.data || []}
                          rowClassName={(record, index) =>
                            `${record && record.isAdd ? "edited-row__add" : ""}`
                          }
                          columns={columnsEnd}
                          scroll={{ x: true }}
                          pagination={false}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <Modal
                  visible={isCreate}
                  title={translation("resultPunish")}
                  onCancel={() => setIsCreate(!isCreate)}
                  okText={translation("accreditation.save")}
                  cancelText= {translation('cancel')}
                  onOk={onFinish}
                >
                  <Form
                    form={form}
                    name="addBooking"
                    autoComplete="off"
                    // onFinish={onFinish}
                    size="small"
                    colon={false}
                    labelAlign="right"
                    labelCol={{ xl: 7, lg: 24, md: 24, span: 7 }}
                    wrapperCol={{ xl: 17, lg: 24, md: 24, span: 17 }}
                  >
                    <FormField
                      translation={translation}
                      label={translation("settingAccreditation.resultName")}
                      name="nameResult"
                    />
                  </Form>
                </Modal>
              </div>
            )}
          </div>
        )}
        onChangeTabs={(key) => {
          if (key === AccreditationNotificationKey) {
            if (setting.enableOperateMenu === 0) {
              return null;
            }
            setUrlForModalDirectLink("/accreditation-public");
          } else if (key === CreateNewCustomerKey) {
            history.push("/schedules?active=2");
          } else if (key === ListEditAccreditationKey) {
            history.push("/list-detail-accreditation");
          } else if (key === InspectionProcessKey) {
            history.push("/inspection-process");
          } else if (key === AccreditationStatisticalKey) {
            history.push("/statistical-accreditation");
          } else if (key === ListReportStaistic) {
            history.push("/list-report-accreditation");
          } else if (key === ListAccreditationKey) {
            history.push("/accreditation");
          }
        }}
        activeKey={AccreditationSettingKey}
        ListEditAccreditation={null}
        InspectionProcess={null}
        CreateNewCustomer={null}
        AccreditationNotification={null}
      />
      {!!crimePlateNumber && (
        <ModalCrime
          plateNumber={crimePlateNumber}
          onClose={() => setCrimePlateNumber("")}
        />
      )}
    </Fragment>
  );
}
export default AccreditationSetting;
