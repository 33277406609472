import Request from './request'

export default class MessageTemplateService {
  static async advanceUserGetByZNSTemplateId(id) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: '/MessageTemplate/advanceUser/findByZNSTemplateId',
        data: { id }
      }).then((result = {})=>{
        const { statusCode, data } = result
        if(statusCode === 200) {
            return resolve(data[0])
        }else{
            return resolve(null)
        }
      })
    })
  }
  static async advanceUserGetById(id) {
    return new Promise(resolve => {
      Request.send({
        method: 'POST',
        path: '/MessageTemplate/advanceUser/findById',
        data: { id }
      }).then((result = {})=>{
        const { statusCode, data } = result
        if(statusCode === 200) {
            return resolve(data)
        }else{
            return resolve(null)
        }
      })
    })
  }
}
