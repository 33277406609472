import Request from "./request"
export default class StationOperateResult {
  static async find(data = {}) {
    return new Promise(resolve=>{
      Request.send({
        method: 'POST',
        path: '/StationOperateResult/advanceUser/find',
        data
      }).then((result = {})=>{
        const { statusCode, data } = result
        if(statusCode === 200) {
          return resolve(data)
        }else{
          return resolve([])
        }
      })
    })
  }

  static async create(data = {}) {
    return new Promise(resolve=>{
      Request.send({
        method: 'POST',
        path: '/StationOperateResult/advanceUser/create',
        data
      }).then((result = {})=>{
        const { statusCode } = result
        if(statusCode === 200) {
          return resolve(result)
        }else{
          return resolve(result)
        }
      })
    })
  }

  static async findById(data = {}) {
    return new Promise(resolve=>{
      Request.send({
        method: 'POST',
        path: '/CustomerRecord/advanceUser/findById',
        data
      }).then((result = {})=>{
        const { statusCode } = result
        if(statusCode === 200) {
          return resolve({ issSuccess: true })
        }else{
          return resolve({ issSuccess: false })
        }
      })
    })
  }

  static async updateById(data = {}) {
    return new Promise(resolve=>{
      Request.send({
        method: 'POST',
        path: '/StationOperateResult/advanceUser/updateById',
        data
      }).then((result = {})=>{
        const { statusCode } = result
        if(statusCode === 200) {
          return resolve({ issSuccess: true })
        }else{
          return resolve({ issSuccess: false })
        }
      })
    })
  }

  static async deletedById(data = {}) {
    return new Promise(resolve=>{
      Request.send({
        method: 'POST',
        path: '/StationOperateResult/advanceUser/deletedById',
        data
      }).then((result = {})=>{
        const { statusCode } = result
        if(statusCode === 200) {
          return resolve({ issSuccess: true })
        }else{
          return resolve({ issSuccess: false })
        }
      })
    })
  }
}