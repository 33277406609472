import { Button } from "antd";
import React from "react";
const BasicButtonPrimary = ({
  disabled = false,
  size = null,
  htmlType='',
  text,
  loading = false,
  icon,
  ghost,
  onclick = () => {},
  onchange = () => {},
  key = null,
  style={},
  danger = false,
  className = "",
  block = false,
}) => {
  return (
    <Button
      danger={danger}
      style={style}
      key={key}
      ghost={ghost}
      loading={loading}
      size={size}
      disabled={disabled}
      htmlType={htmlType}
      className={className}
      type="primary"
      onClick={onclick}
      onChange={onchange}
      icon={icon}
      block={block}
    >
      {text}
    </Button>
  );
};

export default BasicButtonPrimary;
