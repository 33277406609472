import Request from "./request"
export default class CustomerRecordResultService {
    static async find(data = {}) {
        return new Promise(resolve => {
            Request.send({
                method: 'POST',
                path: '/CustomerRecordResult/advanceUser/find',
                data
            }).then((result = {}) => {
                const { statusCode, data } = result
                if (statusCode === 200) {
                    return resolve(result)
                } else {
                    return resolve([])
                }
            })
        })
    }
    static async addOrUpdateRecordResult(data = {}) {
        return new Promise(resolve => {
            Request.send({
                method: 'POST',
                path: '/CustomerRecordResult/advanceUser/addRecordResult',
                data
            }).then((result = {}) => {
                const { statusCode, data } = result
                if (statusCode === 200) {
                    return resolve(result)
                } else {
                    return resolve([])
                }
            })
        })
    }
    static async findById(data={}){
        return new Promise(resolve => {
            Request.send({
                method: 'POST',
                path: '/CustomerRecordResult/advanceUser/findById',
                data
            }).then((result = {}) => {
                const { statusCode, data } = result
                if (statusCode === 200) {
                    return resolve(result)
                } else {
                    return resolve({})
                }
            })
        })
    }
}