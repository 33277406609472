export const TYPE_NOTIFICATION_SHOW = [
  {
    value: 1,
    field: "customerRecordPlatenumber",
    label: "Thông báo theo biển số xe",
    columnName:"Biển số xe"
  },
  {
    value: 2,
    field: "customerRecordFullName",
    label: "Thông báo theo tên",
    columnName:"Tên khách hàng"
  },
  {
    value: 3,
    field: "customerRecordPhone",
    label: "Thông báo theo số điện thoại",
    columnName:"Số điện thoại"
  },
  {
    value: 4,
    field: "index",
    label: "Thông báo theo số thứ tự",
    columnName:"Biển số xe"
  },
];
