import React, { memo, useEffect, useState } from "react";
import {
  Space,
  Popconfirm,
  Modal,
  Form,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  DeleteOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import "./completed.scss";
import CustomerRecordResultService from "services/CustomerRecordResult";

const Completed = ({
  status,
  onClick,
  isShowAction = false,
  listFailReason = [],
  note = "",
  customerRecordId
}) => {
  const [form] = Form.useForm();
  const [selectValue, setSelectValue] = useState(null);
  const { t: translation } = useTranslation();
  const [isShowModal, setIsShowModal] = useState(false);
  const LIST_STATUS = {
    Completed: translation("accreditation.statusText.completed"),
    Failed: translation("accreditation.statusText.failed"),
    Canceled: translation("accreditation.statusText.canceled"),
    New: "Mới",
  };

  useEffect(() => {
    if (customerRecordId&&isShowModal&&status==="Failed") {
      CustomerRecordResultService.findById({ id: customerRecordId }).then((res) => {
        form.setFieldsValue({ reasonFailed: {
          value: res.data?.customerRecordResultId,
          label: res.data?.customerRecordResultNote,
        } });
      });
    }
  }, [customerRecordId, isShowModal]);

  const handleConfirmFailed = () => {
    onClick("Failed", selectValue);
    setIsShowModal(false);
  };
  const showStatus = (status) => {
    switch (status) {
      case "Failed":
        return note ? note : translation("accreditation.statusText.failed");
      default:
        return LIST_STATUS[status];
    }
  };

  return (
    <div className={`completed__text--${status ? status : ""}`}>
      {!isShowAction ? (
        showStatus(status)
      ) : (
        <Space size={16}>
          <Popconfirm
            title={translation("accreditation.popConfirm", {
              status: translation(
                "accreditation.statusText.canceled"
              ).toLowerCase(),
            })}
            onConfirm={() => onClick("Canceled")}
            okText={translation("accreditation.yes")}
            cancelText={translation("accreditation.no")}
          >
            <div className="completed__icon">
              <DeleteOutlined style={{ color: "#8C8C8C" }} />
            </div>
          </Popconfirm>
          <div className="completed__icon" onClick={() => setIsShowModal(true)}>
            <CloseCircleOutlined style={{ color: "#F5222D" }} />
          </div>
          <Popconfirm
            title={translation("accreditation.popConfirm", {
              status: translation(
                "accreditation.statusText.completed"
              ).toLowerCase(),
            })}
            onConfirm={() => onClick("Completed")}
            okText={translation("accreditation.yes")}
            cancelText={translation("accreditation.no")}
          >
            <div className="completed__icon">
              <CheckCircleOutlined style={{ color: "#389E0D" }} />
            </div>
          </Popconfirm>
        </Space>
      )}
      <Modal
        width={600}
        visible={isShowModal}
        onCancel={() => setIsShowModal(false)}
        centered
        okText={translation("accreditation.save")}
        cancelText= {translation('cancel')}
        onOk={handleConfirmFailed}
        title={translation("settingAccreditation.resultFailed")}
      >
        <div className="text-center p-4">
          <Form
            name="confirmReasonFailed"
            autoComplete="off"
            size="small"
            form={form}
            colon={false}
            labelAlign="right"
            initialValues={{ reasonFailed: listFailReason[0] || {
              value: "",
              label: ""
            } }}
          >
            <Form.Item
            label={translation("settingAccreditation.resultFailed")}
              className="w-100"
              name="reasonFailed"
              rules={[
                {
                  required: true,
                  message: translation("settingAccreditation.resultRequired"),
                },
              ]}
            >
                <Select
                  onChange={(value) => {
                    setSelectValue(value);
                  }}
                  size="middle"
                  placeholder={translation("settingAccreditation.resultRequired")}
                >
                  {listFailReason&&listFailReason?.length>0&&listFailReason.map((item) => {
                    return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>;
                  })}
                </Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default memo(Completed);
